import React from 'react';

function InstagramScreen(): JSX.Element {
    return (
        <div className='bg-black text-white font-bold text-center h-60 flex items-center justify-center'>
            <span className=''>
                wouldn't waste my time on this
            </span>
        </div>
    );
}

export default InstagramScreen;
